/** @module                             Hooks */

import { useState, useEffect }          from "react";

import { useTranslation }               from "react-i18next";

import useFetch                         from "../../Hooks/useFetch";

import useCreate                        from "../../Hooks/Checkout/createOrder";

import { gql, useQuery }                from "@apollo/client";

import { pdf }                          from "@react-pdf/renderer";

/** @module                             Mutations */

import { CREATE_ORDER }                 from "../../Mutations/Checkout";

/** @module                             Queries */

import { OrderOptions }                 from "../../Queries/Orders";

import { CartSummary }                  from "../../Queries/Orders";

import { DELIVERY_PLANS }               from "../../Queries/Orders";

import Collection                       from "../../Models/CartProducts";

/** @module                             Components */

import Breadcrumbs                      from "../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader                  from "../../Components/Layout/Loaders/Loader";

import Customer                         from "../../Components/Orders/Checkout/Refurbished/Customer";

import Delivery                         from "../../Components/Orders/Checkout/Refurbished/Delivery";

import Payments                         from "../../Components/Orders/Checkout/Refurbished/Payments";

import Summary                          from "../../Components/Orders/Checkout/Refurbished/Summary";

import Agreement                        from "../../Components/Orders/Checkout/Refurbished/Agreement";

import PDFDocument                      from "../../Components/Orders/Checkout/PDF/Document/Document";

import useRandomOrderId                 from "../../Hooks/Checkout/useRandomOrderId";

import PaymentMethods                   from "../../Components/Orders/Checkout/Refurbished/PaymentMethods/PaymentMethods";

const 

Checkout = () => { const 
    
    { t } = useTranslation (),

    [ validation, setValidation ] = useState ( true ),

    { loading : loadingCartProducts, data : cartProducts } = Collection (),

    generateOrderId = useRandomOrderId (),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading : loadOptions, data: orderOptions } = useFetch ( OrderOptions ),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading : loadingDelivery, data: deliveryPlans } = useFetch ( DELIVERY_PLANS ),

    /** @event useState form fields */

    [ type, setType ] = useState ( "private" ),

    [ policy, setPolicy ] = useState ( false ),

    [ state, setStateValue ] = useState ( {

        assembly: false,

        mobilePhoneNr: "",

        email: "",

        firstName: "",

        lastName: "",

        deliveryMethod: "",

        deliveryPlanId: "",

        paymentMethod: "",

        city: "Riga",

        countryCode: "LV",

        postCode: "",

        address: "",

        deliveryPrice: "",

        regNr: "",

        pvnRegNr: "",

        companyName: "",

        userNotes: "",

        products: mutationProducts (),

        invoiceNumber: generateOrderId || ""

    } ),

    { data : contactsInfo, loading : loadingContactsInfo } = useQuery ( gql`query { contactInfo }` ),

    /** 
     * 
     * 
     * @event useFetch 
     * 
     * @returns query data, loading state
     * 
     */

    { loading : loadSummary, data: orderSummary } = useFetch ( CartSummary, 
        
        loadingDelivery ? 

        {

            productParams: JSON.parse ( localStorage.getItem ( "cart" ) ) || JSON.parse ( "[]" ),

            city: state.city || "Riga",

            countryCode: state.countryCode || "LV",

            vat: true,

            deliveryPrice: 0,

            servicePrice: 0

        } 
        
            :

        {

            productParams: JSON.parse ( localStorage.getItem ( "cart" ) ) || JSON.parse ( "[]" ),

            city: state.city || "Riga",

            countryCode: state.countryCode || "LV",

            vat: true,

            deliveryPrice: 0,

            deliveryPlanId: parseInt ( state.deliveryPlanId ) || 1,

            servicePrice: 0

        } 
    
    ),

    /** 
     * 
     * @param { Object } e
     * 
     * @event handleChange 
     * 
     */

    handleChange = e => { const { name, value, type, checked } = e.target;

        if ( type === "checkbox" ) return setStateValue ( prev => ( { ...prev, [ name ]: checked } ) );

        setStateValue ( prev => ( { ...prev, [ name ]: value } ) );

    },

    /** @callback useCreate create mutation hook */

    { 
        
        createMutation, 
        
        loading, 
        
        complete, 
        
        returnData, 
        
        errors
    
    } = useCreate ( CREATE_ORDER ),
    

    submitOrder = async ( e ) =>  {

        e.preventDefault ();

        const blob = await pdf (

            <PDFDocument

                state={ state }

                products={ cartProducts }

                order={ orderSummary }

                type={ state?.paymentMethod || "invoice" }
            
                contacts={ contactsInfo?.contactInfo || {} }
            
            /> 

        ).toBlob ();

        createMutation ( { ...state, pdf: new File ( [ blob ], `${ generateOrderId }.pdf` ) } );

    };

    /** @event useEffect */

    useEffect ( () => {

        checkoutValidation ( state, setValidation, type, policy );

    }, [ state, type, policy ] );

    useEffect ( () => {

        if ( orderSummary ) {

            setStateValue ( prev => ( { 
                
                ...prev, deliveryPrice: orderSummary.orderPriceCalculator.delivery.toFixed ( 2 )
            
            } ) );

        }

    }, [ orderSummary ] );

    /** @returns */

    return ( 
    
        <div className="container max-content-width">

            <Breadcrumbs collection={ [

                { name: t ( "checkout" ), URI: "/orders/checkout" }

            ] } type="basic" />

            <div className="checkout_sp">

                <h2>{ t ( "checkout" ) }</h2>

                <div className="section">

                    <span>

                        { t ( "basic_information" ) }

                    </span>

                </div>

                <Customer 
                
                    state={ state } 
                
                    action={ handleChange } 
                
                    __={ t } 
                
                    type={ type } 
                
                    setType={ setType } />

                <div className="section">

                    <span>

                        { t ( "delivery" ) }
                        
                    </span>

                </div>

                { /** @todo add delivery method component */

                    loadingDelivery ? <ComponentLoader /> : 

                        <Delivery 
                            
                            state={ state } 
                            
                            action={ handleChange } 
                            
                            __={ t }
                            
                            data={ deliveryPlans } />

                }

                <div className="section">

                    <span>

                        { t ( "notes" ) }

                    </span>

                </div>

                <div className="textarea">

                    <div className="field">

                        <label htmlFor="userNotes">

                            { t ( "notes" ) }

                        </label>

                        <textarea 
                    
                            name="userNotes" 
                        
                            id="userNotes" 
                        
                            value={ state.userNotes } 
                        
                            onChange={ e => handleChange ( e ) }>

                                { state.userNotes }

                        </textarea>

                    </div>

                </div>

                <div className="section">

                    <span>
                        
                        { t ( "order_summary" ) }
                        
                    </span>

                </div>

                { /** @todo add summary component */

                    loadSummary ? <ComponentLoader /> : 

                        <Summary 
                        
                            data={ orderSummary } 
                        
                            __={ t } />

                }

                <div className="section">

                    <span>

                        { t ( "payment" ) }

                    </span>

                </div>

                { /** @todo add payment method component */

                    // loadOptions ? <ComponentLoader /> : 

                    //     <Payments 
                        
                    //         state={ state } 
                        
                    //         action={ handleChange } 
                        
                    //         __={ t } 
                        
                    //         data={ orderOptions } />

                }

                { /** @todo add payment method component */

                    loadOptions ? <ComponentLoader /> : 

                        <PaymentMethods 
                        
                            state={ state } 
                        
                            action={ handleChange } 
                        
                            trans={ t } 
                        
                            data={ orderOptions } />

                }

                <Agreement view={ policy } set={ setPolicy } __={ t } />

                <div className="submit">

                    <button disabled={ ( validation || loading || loadingCartProducts ) ? true : false } className="submit" onClick={ submitOrder }>

                        {

                            ( ! loading ) ?

                                t ( "place_order" )

                            : 

                                t ( "submitting" )

                        }
                        
                    </button>

                    { validation && 
                    
                        <span>{ t ( "checkout_validation_error" ) }</span>
                    
                    }

                </div>

            </div>

        </div>
        
    );

},

/**
 * 
 * @param { Object } data
 * 
 * @param { Function } action
 * 
 * @param { String } customerType
 * 
 * @param { Boolean } policy
 * 
 * @event checkoutValidation
 * 
 * @returns validation state
 * 
 * @description validates checkout form data
 * 
 * @example checkoutValidation ( state, setValidation, type, policy );
 * 
 */

checkoutValidation = ( data, action, customerType, policy ) => {

    /** validate customer data */

    if ( 
        
        data.firstName === "" 

        || 
        
        data.lastName === ""

        || 
        
        data.email === ""

        || 
        
        data.mobilePhoneNr === ""

        || 
        
        data.deliveryMethod === ""

        || 
        
        data.paymentMethod === ""
        
    ) return action ( true );

    /** validate delivery data */

    if ( data.deliveryMethod === "tireshop_delivery" ) {

        if (

            data.city === "" 
            
            || 
            
            data.countryCode === ""
            
            || 
            
            data.postCode === ""
            
            || 
            
            data.address === ""

        ) return action ( true );

    }

    /** validate legal customer data */

    if ( customerType === "legal" ) {

        if (

            data.regNr === "" 
            
            || 
            
            data.pvnRegNr === "" 
            
            || 
            
            data.companyName === ""

        ) return action ( true );

    }

    /** validate policy state */

    if ( ! policy ) return action ( true );

    /** @returns */

    return action ( false );

},

mutationProducts = () => { const

    items = [];

    JSON.parse ( localStorage.getItem ( "cart" ) ).map ( item => {

        items.push ( {

            productId: parseInt ( item.id ),

            productCount: parseInt ( item.qty )

        } );

    } );

    /** @returns */

    return items;

}

/** @exports Checkout */
 
export default Checkout;