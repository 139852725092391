import styles from "./PaymentMethods.module.css";

import { useTranslation } from "react-i18next";


export default function PaymentMethod ( { description, icon, notice, ...props } ) {


    const { t } = useTranslation ();


    return (

        <div className={ styles.button }>

            <label htmlFor={ props.value } className={ styles.label }>

                <input 

                    type="radio" 

                    className={ styles.input }

                    { ...props }
                    
                />

                <div className={ styles.content }>

                    <span className={ styles.icon }>

                        { icon }

                    </span>

                    <div className={ styles.text }>

                        <p className={ styles.name }>{ t ( props.value ) }</p>

                        <p className={ styles.description }>{ description }</p>

                        <p className={ styles.notice }>{ notice }</p>

                    </div>

                </div>

            </label>
                
        </div>
        
    );


};