import styles from "./PaymentMethods.module.css";

import { useEffect } from "react";

import { useQuery } from "@apollo/client";

import { CartProducts } from "../../../../../Queries/Orders";

import { onCheckoutOption } from "../../../../../Misc/DataLayer";

import PaymentMethod from "./Item";

import { FaDotCircle, FaRegCreditCard } from "react-icons/fa";

import { FaFileInvoiceDollar } from "react-icons/fa6";

import { BsCashCoin } from "react-icons/bs";

import ComponentLoader from "../../../../Layout/Loaders/Loader";


export default function PaymentMethods ( { state, action, trans, data = [] } ) {

    const cardData = JSON.parse ( localStorage.getItem ( "cart" ) ) || JSON.parse ( "[]" );

    const { data: productsData, loading } = useQuery ( CartProducts, { variables: { id: cardData.map ( item => item.id ) } } );

    const cashPayment = isCashPaymentAvailable ( productsData );

    const noticeHash = {

        cash : ! cashPayment ? trans ( "cash_prepayment" ) : "",

    };

    const descriptionHash = {

        cash : trans ( "payment_desc_cash" ),

        online_card_payment : trans ( "payment_desc_online_card_payment" ),

        invoice : trans ( "payment_desc_invoice" )

    };

    const iconHash = {

        cash : <BsCashCoin size={ 24 } />,

        online_card_payment : <FaRegCreditCard size={ 24 } />,

        invoice : <FaFileInvoiceDollar size={ 24 } />

    };




    /** @event useEffect */
    
        useEffect ( () => {
    
            const filteredPaymentMethods = data?.orderOptions?.payment_method?.filter(option => option.value !== "cash");

            action ( {
    
                target: {
    
                    name: "paymentMethod",
    
                    value: cashPayment ? data?.orderOptions?.payment_method[0]?.value : filteredPaymentMethods[0]?.value
    
                }
    
            } );
    
        }, [] );

    
        useEffect ( () => { ( state?.paymentMethod ) && onCheckoutOption ( 3, state?.paymentMethod ) }, [ state?.paymentMethod ] );


        if ( loading ) return <ComponentLoader />;


    return (


        <div className={ styles.container }>
            
            {

                data?.orderOptions?.payment_method?.map ( ( option, index ) =>

                    <PaymentMethod 

                        icon={ iconHash[ option?.value ] || <FaDotCircle /> }

                        key={ option?.value || index }

                        id={ option.value }

                        value={ option.value }

                        name="paymentMethod"

                        notice={ noticeHash[ option?.value ] || "" }

                        checked={ state?.paymentMethod === option?.value ? true : false }

                        description={ descriptionHash[ option?.value ] || "-" }

                        onChange={ e => action ( e ) }

                        disabled={ option?.value === "cash" && ! cashPayment }

                    />

                )

            }
            
        </div>


    );


};

function isCashPaymentAvailable ( data ) {

    return data?.product?.edges?.every ( product => product?.node?.productVendors?.every ( vendor => vendor?.qty !== null && vendor?.qty > 0 ) );

};