import styles from "./FundNotice.module.css";

import NGEUNAP from "../../../../Assets/NGEUNAP.png";


export default function FundNotice () {


    return (

        <div className={ styles.container }>

            <img src={ NGEUNAP } className={ styles.image } alt="Finansē Eiropas Savienība NextGenerationEU un Nacionālās attīstības plāns." />

            <div className={ styles.notice }>

                <p className={ styles.title }>Finansē Eiropas Savienība NextGenerationEU un Nacionālās attīstības plāns.</p>

                <p className={ styles.text }>Projekts tiek īstenots ar Eiropas fondu atbalstu,  digitalizācijas programmas ietvaros ar ES Atveseļošanas fonda saņemto finansiālo atbalstu. SIA Tireshop ir noslēdzis līgumu Nr. SKV-L-2022/434 ar Latvijas Investīciju un attīstības aģentūru, EDIC “Digitālā transformācija” līgums Nr. EDIC/GLG/2023-706 ( 2024.gada 30.aprīlī ) par atbalsta saņemšanu pasākuma ietvaros, ko līdzfinansē Eiropas Savienība projektā "Nacionālais attīstības plāns".</p>

            </div>

        </div>

    );


};